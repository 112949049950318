import Vue from 'vue'
import VueNativeNotification from 'vue-native-notification'

import notificationMixin from '~/assets/mixins/global/notificationMixin'
import globalMixin from '~/assets/mixins/global/globalMixin'
import whatsappMixin from '~/assets/mixins/whatsapp/whatsappMixin'
import foreground from '~/assets/mixins/firebase/foreground'
import foregroundMqtt from '~/assets/mixins/mqtt/foregroundMqtt'
import conversation from '~/assets/mixins/messaging/conversation'
import survey from '~/assets/mixins/setting/satisfaction_survey'
import contactObjectMixin from '~/assets/mixins/contact/contactMixin'
import mixpanel from '~/assets/mixins/metric/mixpanelMixin'
import zohoMixin from '~/assets/mixins/zoho/zohoMixin'
import moengageMixin from '~/assets/mixins/metric/moengageMixin'

Vue.use(VueNativeNotification, {
  requestOnNotify: true,
})

Vue.mixin(notificationMixin)
Vue.mixin(globalMixin)
Vue.mixin(whatsappMixin)
Vue.mixin(foreground)
Vue.mixin(foregroundMqtt)
Vue.mixin(conversation)
Vue.mixin(survey)
Vue.mixin(contactObjectMixin)
Vue.mixin(mixpanel)
Vue.mixin(zohoMixin)
Vue.mixin(moengageMixin)
